/* eslint-disable no-nested-ternary */
import {
  Button,
  CardMedia,
  Container, Theme, Toolbar, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Content, ContentInteractionAction, MetaItem } from '../../common/models/content/Content';
import ContentArticleBodyView from './ContentArticleBodyView';
import { ContentArticleViewSkeleton } from './ContentArticleViewSkeleton';
import { getContentById, getContentStatus } from '../../store/thunks/contentThunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectIsContentPreview } from '../../store/slices/contentSlice';
import { selectBackUrl, UpdateShowDashboardToolbar } from '../../store/slices/dashboardSlice';
import { ContentEventType } from '../../common/constants';
import { useTracking } from '../../common/useTracking';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import { TestIds } from '../../assets/testIds';
import { HOME_ROUTE } from '../../routes/namedRoutes';
import { getFormattedDate } from '../../common/utils/DateUtils';
import { getFormattedTestId } from './ContentCommonFunctions';

const btnStyle = (theme: Theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  textTransform: 'none',
  border: `${theme.spacing(0.125)} solid ${theme.palette.color27.main}`,
  color: theme.palette.color17.main,
  '&:hover': {
    borderColor: theme.palette.color27.main,
  },
} as const);

export function ContentArticleView() {
  const { id } = useParams();
  const theme = useTheme();
  const [content, setContent] = useState<Content>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const trackEvent = useTracking();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isContentPreview = useAppSelector(selectIsContentPreview);
  const backUrl = useAppSelector(selectBackUrl);
  const { memberId } = useAppSelector(selectMemberSession);
  const [isSaved, setIsSaved] = useState(false);

  const publishedDate =
    useMemo(
      () => getFormattedDate(content?.attributes?.publishedAt || ''),
      [content],
    );

  const articleTopics =
    useMemo(() => {
      const metaData = content?.attributes?.metadata || [];
      if (metaData.length === 0) return [];
      return metaData.filter((item: MetaItem) => item.category.toLowerCase() === 'topic');
    }, [content]);

  useEffect(() => {
    dispatch(UpdateShowDashboardToolbar(true));
    return () => {
      // hide toolbar on unmount
      dispatch(UpdateShowDashboardToolbar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getContentByIdResponse = dispatch(getContentById(Number(id))).then(unwrapResult);
    const getContentStatusResponse = dispatch(getContentStatus(id || '')).then(unwrapResult);
    Promise.all([
      getContentByIdResponse,
      getContentStatusResponse,
    ])
      .then(([contentRes, contentStatusRes]) => {
        setContent(contentRes);
        if (contentStatusRes && contentStatusRes[Number(id)]) {
          setIsSaved(contentStatusRes[id || ''].save);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(backUrl || HOME_ROUTE);
  };

  const fireContentSavedEvent = () => {
    setIsSaved(!isSaved);
    const action =
      isSaved ? ContentInteractionAction.Unsave : ContentInteractionAction.Save;
    trackEvent({
      optimusEventType: ContentEventType,
      optimusEvents: [
        {
          name: 'content_interaction',
          detail: {
            action,
            contentId: content?.id,
            memberId,
            contentTitle: content?.attributes?.title || null,
            contentCreatorName: content?.attributes?.content_owner?.data?.attributes?.display_name,
          },
        },
      ],
    });
  };

  return (
    <>
      <Toolbar
        sx={{
          position: 'relative',
          p: `${theme.spacing(2)} ${theme.spacing(3)}`,
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.palette.color27.main}`,
          backgroundColor: theme.palette.common.white,
          [theme.breakpoints.up('md')]: {
            position: 'absolute',
            left: '18.8%',
            width: '80.4%',
            top: theme.spacing(2),
          },
          ...(isContentPreview ? {
            width: '100%',
            maxWidth: 'sm',
            justifySelf: 'center',
          } : {}),
        }}
      >
        <Button
          data-testid={TestIds.contentArticleView.backBtn}
          startIcon={<ArrowBackIosIcon sx={{ fontSize: `${theme.spacing(2)} !important` }} />}
          sx={[btnStyle,
            {
              backgroundColor: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.common.white,
              },
            },
          ]}
          onClick={goBack}
        >
          Back
        </Button>
        {isDesktop && !isContentPreview &&
          <Typography variant="h6" component="div"
            data-testid={TestIds.contentArticleView.contentTitle}
            sx={{
              flexGrow: 1,
              textAlign: 'center',
            }}>
            {content?.attributes?.title}
          </Typography>
        }
        <Button
          data-testid={TestIds.contentArticleView.saveBtn}
          disabled={isContentPreview}
          startIcon={isSaved ?
            <BookmarkIcon sx={{ color: theme.palette.secondary.main }} /> :
            <BookmarkBorderOutlinedIcon sx={{ color: theme.palette.color17.main }} />
          }
          sx={[btnStyle,
            {
              backgroundColor: isSaved ? theme.palette.grey[100] : theme.palette.common.white,
              '&:hover': {
                backgroundColor: isSaved ? theme.palette.grey[100] : theme.palette.common.white,
              },
            },
          ]}
          onClick={fireContentSavedEvent}
        >
          Save
        </Button>
      </Toolbar >
      <Container maxWidth={isContentPreview ? 'sm' : 'lg'}
        sx={{
          marginTop: isContentPreview ? 0 : theme.spacing(9),
          paddingBottom: theme.spacing(6),
          backgroundColor: theme.palette.common.white,
          [theme.breakpoints.down('md')]: {
            p: `${theme.spacing(5)} ${theme.spacing(2.5)} ${theme.spacing(5)} ${theme.spacing(2.5)}`,
            marginTop: 0,
          },
        }}>
        {!content ? <ContentArticleViewSkeleton /> :
          (<Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(5),
            [theme.breakpoints.down('md')]: {
              mt: theme.spacing(3),
            },
          }}>
            <Box
              sx={{
                textAlign: 'center',
                justifyItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2.5),
              }}
            >
              <Typography variant={isDesktop ? 'h12' : 'h2'}
                data-testid={TestIds.contentArticleView.contentTitle}>
                {content?.attributes?.title}
              </Typography>
              <Typography variant={isDesktop ? 'body1' : 'body8'} >
                {content?.attributes?.short_description}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {articleTopics?.map((item, index) => (
                  <Box
                    data-testid={`${TestIds.contentArticleView.contentTopic}-${getFormattedTestId(item.name)}`}
                    key={index}
                    sx={{
                      mr: theme.spacing(1.5),
                      display: 'flex',
                      padding: `${theme.spacing(1)} ${theme.spacing(1.5625)}`,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: theme.spacing(12.5),
                      backgroundColor: theme.palette.common.white,
                      border: `${theme.spacing(0.125)} solid ${theme.palette.color27.main}`,
                      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
                    }}
                  >
                    <Typography variant='body8'>{item.name}</Typography>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant='body8'
                  data-testid={TestIds.contentArticleView.contentOwnerName} >
                  By {content?.attributes?.content_owner?.data?.attributes?.display_name}
                </Typography>
                <Typography variant='body8'
                  data-testid={TestIds.contentArticleView.contentPublishedDate} >
                  {publishedDate}
                </Typography>
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <CardMedia
                sx={() => ({
                  maxWidth: theme.spacing(120),
                  height: isDesktop ? theme.spacing(52.75) : theme.spacing(27.5),
                  width: isDesktop ? (isContentPreview ? theme.spacing(68.75) : theme.spacing(93.75)) : '100%',
                  alignSelf: 'center',
                })}
                component='div'
                image={content?.attributes?.hero?.data?.attributes?.url}
              />
            </Box>
            <Box>
              <ContentArticleBodyView
                richText={content?.attributes?.body} />
            </Box>
          </Box>
          )
        }
      </Container >
    </>
  );
}
