import moment from 'moment';
// Go live date for supporting only home depot onboarding for service fusion
// TODO: replace with the actual date when the changes are deployed to production
const ONLY_HOME_DEPOT_GO_LIVE_DATE = process.env.REACT_APP_ONLY_HOME_DEPOT_GO_LIVE_DATE;

export function isPriorCompanyEnrollment(enrollmentDate: string | undefined): boolean {
  console.log(`EnrollmentDate: ${enrollmentDate}`);
  console.log(`Live date: ${ONLY_HOME_DEPOT_GO_LIVE_DATE}`);
  if (!enrollmentDate || !ONLY_HOME_DEPOT_GO_LIVE_DATE) {
    return false;
  }
  // Convert to UTC Moment objects
  const enrollmentDateMomentUtc = moment.utc(enrollmentDate);
  const liveDateMomentUtc = moment.utc(ONLY_HOME_DEPOT_GO_LIVE_DATE);

  // Compare the dates
  return enrollmentDateMomentUtc.isBefore(liveDateMomentUtc);
}

export function getFormattedDate(date: string) {
  return moment(date).format('MMMM DD, YYYY');
}
