import {
  Box, SvgIcon, useMediaQuery, useTheme,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import { t } from 'i18next';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import RewardsIcon from '@mui/icons-material/SellOutlined';
import PartnerIcon from '@mui/icons-material/HandshakeOutlined';
import FilledPartnerIcon from '@mui/icons-material/Handshake';
import FilledHomeIcon from '@mui/icons-material/Home';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FilledAccountIcon from '@mui/icons-material/Person';
import FilledRewardsIcon from '@mui/icons-material/Sell';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { DashboardDesktopLayout, DashboardSubMenuItemsProps } from './DashboardDesktopLayout';
import { DashboardMobileLayout } from './DashboardMobileLayout';
// import ContentSearchView from '../Content/ContentSearchView';
// import { DashboardRewardBanner } from './DashboardRewardBanner';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import { TopicsCard } from './TopicsCard';
import { useAppDispatch } from '../../store/hooks';
import { getTopTopics } from '../../store/thunks/contentThunk';
import { logout } from '../../store/thunks/authenticationThunk';
import {
  HOME_ROUTE, isOnboarding, MYACCOUNT_PREFERENCES_ROUTE, MYACCOUNT_ROUTE, MYACCOUNT_SETTINGS_ROUTE,
  PARTNERS_ROUTE, REWARDS_ROUTE, SAVED_ROUTE,
  SEARCH_ROUTE,
  SUPPORT_CONTACT_US_ROUTE,
  SUPPORT_FAQ_ROUTE,
  SUPPORT_ROUTE,
} from '../../routes/namedRoutes';

export interface DashboardScreensLayoutProps {
  children: JSX.Element;
  navElems: {
    name: string;
    path: string;
    Outline: typeof SvgIcon;
    Filled: typeof SvgIcon;
    isCurrent?: boolean;
    subMenuItems?: DashboardSubMenuItemsProps[];
    bottomFixPosition?: boolean;
    onClick?: () => void;
  }[];
}

interface DashboardLayoutProps {
  children: JSX.Element;
}

export function DashboardLayout({
  children,
}: DashboardLayoutProps) {
  const theme = useTheme();
  // treating anything above 'md' as desktop
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { searchFlag } = useFeatureFlags();

  let rightColumnElements: JSX.Element[] = [];
  // let searchElem;
  // const hdSetupFlowCard = <HDSetupFlowCard hideDoneState dashboardLayout />;
  const topicsCard = <TopicsCard />;
  // const rewardBanner = <DashboardRewardBanner />;
  // if (searchFlag) {
  //   searchElem = <ContentSearchView onSubmit={(value) => {
  //     const params = new URLSearchParams();
  //     params.append('query', value || '');
  //     const route = `/search?${params.toString()}`;
  //     console.log('going to ', route);
  //     navigate(route);
  //   }} />;
  //   rightColumnElements = [searchElem, topicsCard];
  // } else {
  //   rightColumnElements = [rewardBanner];
  // }
  rightColumnElements = [topicsCard];
  // push Home Depot instructions card at 2nd position

  // removing hd setup from right column fo now
  /* if (hdOnboardingForSNFlag) {
    rightColumnElements.splice(1, 0, hdSetupFlowCard);
  } */

  useEffect(() => {
    dispatch(getTopTopics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => (
    dispatch(logout({
      isOnboarding: isOnboarding(),
      navigate,
      customAfterLoginPath: '/',
    }))
  );

  const accountSubMenuItems: DashboardSubMenuItemsProps[] = [
    {
      title: `${t('navigation.settings')}`,
      path: MYACCOUNT_SETTINGS_ROUTE,
      isCurrent: MYACCOUNT_SETTINGS_ROUTE === location.pathname,
    },
    {
      title: `${t('navigation.preferences')}`,
      path: MYACCOUNT_PREFERENCES_ROUTE,
      isCurrent: MYACCOUNT_PREFERENCES_ROUTE === location.pathname,
    },
  ];

  const supportSubMenuItems: DashboardSubMenuItemsProps[] = [
    {
      title: `${t('navigation.faq')}`,
      path: SUPPORT_FAQ_ROUTE,
      isCurrent: SUPPORT_FAQ_ROUTE === location.pathname,
    },
    {
      title: `${t('navigation.contactUs')}`,
      path: SUPPORT_CONTACT_US_ROUTE,
      isCurrent: SUPPORT_CONTACT_US_ROUTE === location.pathname,
    },
  ];

  const fullNavElems = [
    {
      name: 'Home',
      path: HOME_ROUTE,
      Outline: HomeIcon,
      Filled: FilledHomeIcon,
      rightColumnElements,
      isCurrent: false,
      isHidden: false,
    },
    {
      name: 'Saved',
      path: SAVED_ROUTE,
      Outline: BookmarkBorderOutlinedIcon,
      Filled: BookmarkIcon,
      isHidden: false,
    },
    {
      name: 'Rewards',
      path: REWARDS_ROUTE,
      Outline: RewardsIcon,
      Filled: FilledRewardsIcon,
      isHidden: false,
    },
    {
      name: 'Partners',
      path: PARTNERS_ROUTE,
      Outline: PartnerIcon,
      Filled: FilledPartnerIcon,
      isHidden: false,
      fullWidth: true,
    },
    {
      name: 'Account',
      path: MYACCOUNT_ROUTE,
      Outline: KeyboardArrowRightIcon,
      Filled: FilledAccountIcon,
      subMenuItems: accountSubMenuItems,
    },
    {
      name: 'Support',
      path: SUPPORT_ROUTE,
      Outline: KeyboardArrowRightIcon,
      Filled: ExpandMoreIcon,
      subMenuItems: supportSubMenuItems,
    },
    {
      name: 'Log out',
      path: '#',
      Outline: LogoutIcon,
      Filled: ExpandMoreIcon,
      bottomFixPosition: true,
      onClick: handleLogout,
    },
  ];

  if (searchFlag) {
    fullNavElems.push(
      {
        name: 'Search',
        path: SEARCH_ROUTE,
        Outline: HomeIcon,
        Filled: FilledHomeIcon,
        rightColumnElements: [topicsCard],
        // This is for mapping the extra column only.
        isHidden: true,
        isCurrent: false,
      },
    );
  }

  const currentRoute = fullNavElems.find((elem) => location.pathname.includes(elem.path));
  if (currentRoute) {
    // highlight Home navigation icon if search is active
    if (currentRoute.name === 'Search') {
      const homeRoute = fullNavElems.find((elem) => elem.name === 'Home');
      if (homeRoute) homeRoute.isCurrent = true;
    } else {
      currentRoute.isCurrent = true;
    }
  }

  const navElems = fullNavElems.filter((elem) => !elem.isHidden);

  return (
    <>
      {
        isDesktop ? <Box
          sx={{
            pr: theme.spacing(1.25),
            pt: theme.spacing(1.25),
          }}
        >
          <DashboardDesktopLayout
            navElems={navElems}
            rightColumnElements={currentRoute?.rightColumnElements}
            fullWidth={currentRoute?.fullWidth}
          >
            {children}
          </DashboardDesktopLayout></Box > : <Box><DashboardMobileLayout navElems={navElems}>
            {children}
          </DashboardMobileLayout></Box>
      }
    </>
  );
}
