/* eslint-disable no-nested-ternary */
import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Alert, Grid, Snackbar, ThemeProvider, useTheme, useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { ContentTheme } from './ContentTheme';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  ResetContentList,
  selectContentList,
  selectContentPageNumber,
  selectGetContentListState,
  selectHasMoreContent,
  selectIsBackNavigation,
  selectScrollPosition,
  UpdateHasMoreContent,
  UpdateIsBackNavigation,
  UpdateIsContentPreview,
  UpdatePageNumber,
  UpdateScrollPosition,
} from '../../store/slices/contentSlice';
import {
  Content,
  FeedInteractionAction,
  SearchInteractionAction,
} from '../../common/models/content/Content';
import ContentCard from './ContentCard';
import { getContent } from '../../store/thunks/contentThunk';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import {
  getHeaderImageUrl, getHeading, getHeroFormat, getImageUrl,
} from './ContentCommonFunctions';
import { useTracking } from '../../common/useTracking';
import { ContentEventType, FeedEventType, SearchEventType } from '../../common/constants';
import ContentCardSkeleton from './ContentCardSkeleton';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import { getRandomInteger } from '../../common/RandomNumberHelper';
import SavedContentEmptyStateView from './SavedContentEmptyStateView';
import ContentSearchView from './ContentSearchView';
import { HOME_ROUTE } from '../../routes/namedRoutes';
import { UpdateBackUrl } from '../../store/slices/dashboardSlice';

interface ContentHomeProps {
  contentViewBaseUrl: string;
  onlySavedContent?: boolean;
  searchTerm?: string;
  topic?: string;
  businessGoal?: string;
  showSearchBar?: boolean;
  fullWidthContainer?: boolean
  setHasNoResults?: (value: boolean) => void
}

export default function ContentHome({
  contentViewBaseUrl,
  onlySavedContent,
  searchTerm,
  topic,
  businessGoal,
  fullWidthContainer = false,
  setHasNoResults,
  showSearchBar = true,
}: ContentHomeProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [hideEndMessage, setHideEndMessage] = React.useState(false);
  const storeContentList = useAppSelector(selectContentList);
  const getContentListState = useAppSelector(selectGetContentListState);
  const storeHasMoreContent = useAppSelector(selectHasMoreContent);
  const [contentList, setContentList] = useState(storeContentList);
  const [hasMoreContent, setHasMoreContent] = useState(storeHasMoreContent);
  const page = useAppSelector(selectContentPageNumber); // the current value of page is from store
  const isBackNavigation = useAppSelector(selectIsBackNavigation);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [algorithm, setAlgorithm] = useState<string | undefined>(undefined);

  const pageSize = 15;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const memberSession = useAppSelector(selectMemberSession);
  const memberId = memberSession.memberId || searchParams.get('memberId');
  const scrollPosition = useAppSelector(selectScrollPosition);
  console.log('scrollPosition', scrollPosition);
  const trackEvent = useTracking();
  const { useContentFromOpenSearchFlag } = useFeatureFlags();
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [showSavedItemsEmptyState, setShowSavedItemsEmptyState] = useState(false);
  const endMessageRef = useRef(null);
  /**
   * Use a new random integer for each page refresh,
   * this will make sure each time different content will be fetched from the backend.
   * When user scrolls down, the same random seed will be used to make sure content
   * is consistent across different pages.
   */
  const [randomContentSeed] = useState(() => getRandomInteger(1, 10000));

  useEffect(() => {
    const algorithmFromParams = searchParams.get('algorithm');
    setAlgorithm(algorithmFromParams || undefined);
  }, [searchParams]);

  useEffect(() => {
    dispatch(UpdateIsContentPreview(false));
    setContentList(storeContentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeContentList]);

  useEffect(() => {
    if (onlySavedContent && contentList.length === 0) {
      setShowSavedItemsEmptyState(true);
    } else {
      setShowSavedItemsEmptyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentList]);

  useEffect(() => {
    setHasMoreContent(storeHasMoreContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeHasMoreContent]);

  useEffect(() => {
    setInitialFetchDone(false);
    if (!isBackNavigation) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(ResetContentList([])); // initial content list state
      dispatch(getContent({
        pageSize,
        page: 1,
        onlySavedContent,
        searchTerm,
        topic,
        businessGoal,
        useContentFromOpenSearchFlag,
        randomContentSeed,
        ...(setHasNoResults ? { setHasNoResults } : {}),
      }))
        .then(unwrapResult)
        .then((response) => {
          setOpenSnackbar(false);
          setHideEndMessage(false);
          const res = response as Content[];
          // Disable pagination for search
          // TODO: revisit this once we support pagination in search
          if (!(searchTerm || topic || businessGoal)) {
            dispatch(UpdatePageNumber(2));
            dispatch(UpdateHasMoreContent(res.length === pageSize));
          } else {
            dispatch(UpdatePageNumber(1));
            dispatch(UpdateHasMoreContent(false));
          }
        })
        .catch(() => {
          setOpenSnackbar(true);
          setHideEndMessage(true);
          dispatch(UpdateHasMoreContent(false));
        })
        .finally(() => {
          setInitialFetchDone(true);
        });
    } else {
      window.scrollTo(0, scrollPosition);
      setInitialFetchDone(true);
    }
    dispatch(UpdateIsBackNavigation(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, businessGoal, topic]);

  // Function to track when an element first appears in the viewport
  function trackElementVisibility(
    elementToTracks: Element[],
    callback: (element: Element | null) => void,
  ) {
    const observers: IntersectionObserver[] = [];

    elementToTracks.forEach((elementToTrack) => {
      if (!elementToTrack) return; // Check if element exists

      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          // The element is in the viewport
          observer.disconnect(); // Disconnect the observer to stop tracking
          callback(elementToTrack);
        }
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      });

      observer.observe(elementToTrack);
      observers.push(observer);
    });

    // Return the observers to be able to disconnect them
    return observers;
  }

  // track content cards impressions
  useEffect(() => {
    const contentCreatorAndTitle = {} as {
      [key: number]: {
        contentTitle: string;
        contentCreatorName: string | null;
      }
    };
    contentList?.forEach((content) => {
      contentCreatorAndTitle[content.id] = {
        contentTitle: content?.attributes?.title,
        contentCreatorName: content?.attributes?.content_owner?.data?.attributes?.display_name,
      };
    });

    const elementsToTrack = Array.from(document.querySelectorAll('[id*="optimus-content"]'));
    const observers = trackElementVisibility(elementsToTrack, (element) => {
      if (element) {
        // console.log(`Content with id "${element.id}" is in the viewport.`);
        const contentId = Number(element.id.split('optimus-content-')[1]);
        trackEvent({
          optimusEventType: ContentEventType,
          optimusEvents: [
            {
              name: 'content_article_impression',
              detail: {
                type: 'impression',
                contentId,
                memberId,
                contentTitle: contentCreatorAndTitle[contentId]?.contentTitle || null,
                contentCreatorName: contentCreatorAndTitle[contentId]?.contentCreatorName || null,
              },
            },
          ],
        });
      }
    });

    // Clean up the observers when the component unmounts
    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentList]);

  // Track when the first content fetch is done
  useEffect(() => {
    if (initialFetchDone) {
      trackEvent({
        optimusEventType: searchTerm ? SearchEventType : FeedEventType,
        optimusEvents: [
          {
            name: searchTerm ? 'search_interaction' : 'feed_interaction',
            detail: {
              memberId,
              action: searchTerm ? SearchInteractionAction.Start : FeedInteractionAction.Visit,
              feedVisit: new Date().toISOString(),
            },
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetchDone]);

  // Track when the user reaches the end of the feed
  useEffect(() => {
    const endMessageElement = document.getElementById('endMessage') as Element;

    const observers = trackElementVisibility([endMessageElement], () => {
      if (endMessageRef.current) {
        trackEvent({
          optimusEventType: searchTerm ? SearchEventType : FeedEventType,
          optimusEvents: [
            {
              name: searchTerm ? 'search_interaction' : 'feed_interaction',
              detail: {
                action: searchTerm ?
                  SearchInteractionAction.ReachEnd :
                  FeedInteractionAction.ReachEnd,
                memberId,
              },
            },
          ],
        });
      }
    });
    // Clean up the observers when the component unmounts
    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endMessageRef.current]);

  const getContentData = () => {
    dispatch(getContent({
      pageSize,
      page,
      onlySavedContent,
      useContentFromOpenSearchFlag,
      randomContentSeed,
      algorithm,
      ...(setHasNoResults ? { setHasNoResults } : {}),
    })).then((response) => {
      const res = response.payload as Content[];
      // our currrent BE ensures that we never get more content in the response than pageSize
      dispatch(UpdateHasMoreContent(res.length === pageSize));
      if (hasMoreContent) {
        dispatch(UpdatePageNumber(page + 1));
      }
    }).catch((e) => {
      console.log(e);
    });
  };

  const trackHeroImageClickedEvent = (content: Content) => {
    trackEvent({
      optimusEventType: ContentEventType,
      optimusEvents: [
        {
          name: 'content_view_more_clicked',
          detail: {
            type: 'open',
            contentId: content?.id,
            memberId,
            contentTitle: content?.attributes?.title || null,
            contentCreatorName: getHeading(content) || null,
          },
        },
      ],
    });
  };

  const displayContentView = (
    content: Content,
  ) => {
    dispatch(UpdatePageNumber(page));
    if (content?.attributes?.type?.toUpperCase() !== 'PODCAST') {
      if (content?.attributes?.external_link) {
        window.open(content?.attributes?.external_link.target, '_blank');
        // track event
        trackHeroImageClickedEvent(content);
      } else if (content?.attributes?.video_url) {
        window.open(content?.attributes?.video_url, '_blank');
      } else {
        dispatch(UpdateScrollPosition(window.scrollY));
        trackHeroImageClickedEvent(content);
        dispatch(UpdateBackUrl(`${contentViewBaseUrl}${location.search}`));
        console.log('window.scrollY', window.scrollY);
        navigate(`${contentViewBaseUrl}/content/${content?.id}`);
      }
    }
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const backToHome = () => {
    navigate(HOME_ROUTE);
  };

  const handleSubmit = (value: string | undefined) => {
    const params = new URLSearchParams();
    params.set('query', value || '');
    const route = `/search?${params.toString()}`;
    navigate(route);
  };

  return (
    <ThemeProvider theme={ContentTheme}>
      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleClose}>
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('error.generalMessage')}
        </Alert>
      </Snackbar>
      {
        showSearchBar &&
        <Box
          sx={{
            padding: `${theme.spacing(3)} 0px 0px ${isMobile ? theme.spacing(2) : theme.spacing(3)}`,
            width: '100%',
          }}
        >
          <ContentSearchView
            showBackBtn={true}
            onBackBtnClick={backToHome}
            onSubmit={handleSubmit}
            value={searchTerm}
          />
        </Box>
      }
      <Box width={'100%'}>
        <InfiniteScroll
          scrollThreshold={0.8}
          dataLength={contentList.length} // This is important field to render the next data
          next={getContentData}
          hasMore={hasMoreContent}
          style={{
            overflow: 'hidden',
          }}
          loader={
            < ContentCardSkeleton sx={fullWidthContainer ? { paddingLeft: 0 } : {}} />
          }
          endMessage={!hideEndMessage && !showSavedItemsEmptyState &&
            <p id='endMessage' style={{
              textAlign: 'center', fontSize: theme.spacing(2.25), marginTop: theme.spacing(12.5), color: theme.palette.color2.main,
            }}
              ref={endMessageRef}>
              {!onlySavedContent &&
                <b>{t('content.feed.endMessage')}</b>
              }
            </p>
          }
        >
          {(getContentListState === 'pending' && !initialFetchDone) ?
            < ContentCardSkeleton sx={fullWidthContainer ? { paddingLeft: 0 } : {}} /> : (
              showSavedItemsEmptyState ? <SavedContentEmptyStateView
                fullWidthContainer={fullWidthContainer} /> :
                (contentList.length > 0 ?
                  contentList
                    ?.map((contentItem: Content, indx: number) => (
                      (
                        <Fragment key={`fragment-${contentItem.id}`}>
                          <Grid item key={contentItem.id}
                            sx={{
                              paddingTop: theme.spacing(3),
                              paddingLeft: fullWidthContainer ? undefined : theme.spacing(3),
                              [theme.breakpoints.down('md')]: {
                                paddingTop: theme.spacing(2),
                                paddingLeft: fullWidthContainer ? undefined : theme.spacing(2),
                              },
                            }}
                          >
                            <Box
                              id={`optimus-content-${contentItem.id}`}
                              sx={{
                                borderRadius: theme.spacing(1.5),
                                border: `${theme.spacing(0.125)} solid ${theme.palette.grey3.main}`,
                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
                              }}
                            >
                              <ContentCard
                                contentId={contentItem.id}
                                contentHeroListener={() => displayContentView(contentItem)}
                                contentText={contentItem.attributes?.title}
                                contentShortDescription={contentItem.attributes?.short_description}
                                categoryText={contentItem.attributes?.type}
                                categoryColor={theme.palette.primary.main}
                                banner={
                                  {
                                    url: getImageUrl(
                                      getHeroFormat(contentItem)?.url
                                      ,
                                    ),
                                    width: getHeroFormat(contentItem)?.width,
                                    height: getHeroFormat(contentItem)?.height,
                                  }}
                                authorTitle={getHeading(contentItem)}
                                publishedDate={contentItem.attributes?.source_published_date ||
                                  contentItem.attributes?.publishedAt}
                                authorText={contentItem.attributes?.banner?.short_description}
                                logo={getHeaderImageUrl(contentItem)}
                                videoUrl={contentItem.attributes?.video_url}
                                contentLikeStatus={contentItem.likeStatus}
                                formId={contentItem.attributes?.form_id}
                                metadata={contentItem.attributes?.metadata}
                              />
                            </Box>
                          </Grid>
                        </Fragment>
                      )
                    )) : null))}
        </InfiniteScroll>
      </Box>
    </ThemeProvider >);
}
