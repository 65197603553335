import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_ROUTE, REWARDS_ROUTE } from '../../../../routes/namedRoutes';
import { setOptedToSkipHdEnrollment, updateNavigationFromOnboarding } from '../../../../store/slices/onboardingSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { useOnboardEventTrackingWithHeap } from '../../../../common/useOnboardEventTrackingWithHeap';
import { TestIds } from '../../../../assets/testIds';

export function PostponeHdSetup() {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const trackOnboardEventsWithHeap = useOnboardEventTrackingWithHeap();

  const onClickHandler = () => {
    const { fromRewards, userInitiatedHdEnrollment } = location.state || {};
    const redirectPath = fromRewards ? REWARDS_ROUTE : HOME_ROUTE;

    if (!userInitiatedHdEnrollment) {
      dispatch(updateNavigationFromOnboarding(true));
    }
    // persit locally that user has opted to skip home depot enrollment
    dispatch(setOptedToSkipHdEnrollment(true));

    // fire event indicating that user opted to skip home depot enrollment
    trackOnboardEventsWithHeap('opted-to-skip-hd-enrollment');

    // navigate to content home page or rewards page
    navigate(redirectPath, {
      state: { fromOnboarding: true },
    });
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Typography sx={{
        fontWeight: 600,
        color: theme.palette.secondary.dark,
        mt: theme.spacing(2.5),
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      }}
      data-testid={TestIds.onboarding.homedepotXtraLaterLnk}
      onClick={onClickHandler}
      >
        {t('hdSetUpWeb.postponeText')}
      </Typography>
    </Box>
  );
}
