import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useMediaQuery, useTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { DashboardJoist } from '../components/Dashboard/DashboardJoist';
import { Verification } from '../components/verification';
import { Notfound } from '../components/NotFound';
import { OnboardHome } from '../components/Onboarding/Home/OnboardHome';
import { OnboardHomeJoist } from '../components/Onboarding/Home/OnboardHomeJoist';
import { Home } from '../components/Home';
import { Dashboard } from '../components/Dashboard/Dashboard';
import ContentArticleViewJoist from '../components/Content/ContentArticleViewJoist';
import ContentArticleViewWrapper from '../components/Content/ContentArticleViewWrapper';
import Rewards from '../components/Dashboard/Rewards';
import LoginLayout from '../components/Authentication/LoginLayout';
import SupportFAQ from '../components/Dashboard/SupportFAQ';
import SupportContact from '../components/Dashboard/SupportContact';
import AccountPreferences from '../components/Dashboard/AccountPreferences';
import AccountSettings from '../components/Dashboard/AccountSettings';
import { VerificationWrapper } from '../components/verificationWrapper';
import ContentPreview from '../components/Content/ContentPreview';
import { SavedContent } from '../components/Dashboard/SavedContent';
import { DashboardHome } from '../components/Dashboard/DashboardHome';
import { ContentSearch } from '../components/Dashboard/ContentSearch';
import {
  EVERPRO_DOMAIN,
  getAppDomain, JOIST_DOMAIN, SERVICE_FUSION_DOMAIN, UNKNOWN_DOMAIN,
} from '../config/domains';
import { useFeatureFlags } from '../config/FeatureFlagsProvider';
import { HDSetupInstructionsDetailView } from '../components/Dashboard/HDOnboardingFlow/HDSetupInstructionsDetailView';
import { Redirect } from '../components/Redirect';
import { useAppSelector } from '../store/hooks';
import { selectOnboardingLandingPageViewDone, selectPartnerOnboardingState } from '../store/slices/onboardingSlice';
import { HomeJoist } from '../components/HomeJoist';
import { OnboardingLogin } from '../components/Authentication/OnboardingLogin';
import { OnboardHDHome } from '../components/Onboarding/Home/OnboardHDHome';
import { useSolutionOrg } from '../config/useSolutionOrg';
import {
  HOME_DEPOT_SETUP_OR_SKIP_ROUTE,
  HOME_DEPOT_SETUP_WEB,
} from './namedRoutes';
import CustomerSupportForm from '../common/CustomerSupportForm';
import { OnboardHDHomeWeb } from '../components/Onboarding/Home/OnboardHDHomeWeb';
import { Partners } from '../components/Dashboard/Partners';

export function NavigationRoutes() {
  const isPartnerOnboardingDone = useAppSelector(selectPartnerOnboardingState);
  const onboardingLandingPageViewDone = useAppSelector(selectOnboardingLandingPageViewDone);
  const { isIframe, enrollOnSplashScreen } = useSolutionOrg();
  const { hdOnboardingForSNFlag } = useFeatureFlags();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  // determine current domain of app
  const appDomain = getAppDomain();

  const contentArticleRoute: RouteObject = {
    path: 'content/:id',
    element: <ContentArticleViewWrapper />,
  };

  // Joist specific routes
  const joistRoutes: RouteObject[] = [
    {
      path: '/dashboard',
      element: <Navigate to='/in-app/dashboard' />,
    },
    {
      path: '/in-app/onboarding',
      // if coming back from Home depot,
      // Home Depot onboarding would have already been done
      // so it will navigate to onbaording form directly
      element: !isPartnerOnboardingDone ? <HomeJoist /> : <Navigate to="/in-app/onboarding/form" />,
    },
    {
      path: '/in-app/onboarding/form',
      // if coming back from Home depot,
      // Home Depot onboarding would have already been done
      // so it will navigate to onboarding form directly
      element: <OnboardHomeJoist />,
    },
    {
      path: '/in-app/onboarding/login',
      element: <OnboardingLogin afterOnboardingLoginPath='/in-app/onboarding' />,
    },
    {
      path: '/in-app/v2/onboarding',
      // if coming back from Home depot,
      // Home Depot onboarding would have already been done
      // so it will navigate to onboarding form directly
      element: !onboardingLandingPageViewDone ? <Home /> : <Navigate to='/in-app/v2/onboarding/form' />,
    },
    {
      path: '/in-app/v2/onboarding/form',
      element: <OnboardHome />,
    },
    {
      path: '/in-app/v2/onboarding/login',
      // need to inject v2 version, default is v1
      element: <OnboardingLogin afterOnboardingLoginPath='/in-app/v2/onboarding' />,
    },
    {
      path: '/in-app/dashboard',
      element: <DashboardJoist />,
    },
    {
      path: '/in-app/dashboard/content/:id',
      element: <ContentArticleViewJoist />,
    },
  ];

  // service nation specific routes
  const sfRoutes: RouteObject[] = [
    {
      path: '/serviceFusion/verification',
      element: <Navigate to={'/paypal-verification'} />,
    },
    {
      path: '/serviceFusion/*',
      element: <Redirect />,
    },
  ];

  const sharedRoutes = [
    {
      path: '/',
      element: <DashboardHome />,
      children: [
        {
          path: 'home',
          children: [
            {
              index: true, // This indicates that this is the default route for the parent path
              element: <Dashboard />,
            },
            { ...contentArticleRoute },
          ],
        },
        {
          path: 'saved',
          children: [
            {
              index: true, // This indicates that this is the default route for the parent path
              element: <SavedContent />,
            },
            { ...contentArticleRoute },
          ],
        },
        {
          path: 'rewards',
          element: <Rewards />,
        },
        {
          path: 'myaccount',
          children: [
            {
              index: true, // This indicates that this is the default route for the parent path
              element: <Navigate to="settings" replace />, // Redirect to 'settings'
            },
            {
              path: 'settings',
              element: <AccountSettings />,
            },
            {
              path: 'preferences',
              element: <AccountPreferences />,
            },
          ],
        },
        {
          path: 'support',
          children: [
            {
              index: true, // This indicates that this is the default route for the parent path
              element: <Navigate to="contact" replace />, // Redirect to 'contact'
            },
            {
              path: 'faqs',
              element: <SupportFAQ />,
            },
            {
              path: 'contact',
              element: <SupportContact />,
            },
          ],
        },
        {
          path: 'search',
          children: [
            {
              index: true, // This indicates that this is the default route for the parent path
              element: <ContentSearch />,
            },
            { ...contentArticleRoute },
          ],
        },
        {
          path: 'partners',
          element: <Partners />,
        },
      ],
    },
    {
      path: '/onboarding',
      // eslint-disable-next-line no-nested-ternary
      element: (!onboardingLandingPageViewDone || enrollOnSplashScreen) ? (isDesktop ? <AnimatePresence mode="wait">
        <Home key={'/onboarding'} />
      </AnimatePresence> : <Home />) : <Navigate to='/onboarding/form' />,
    },
    {
      path: '/onboarding/form',
      element: isDesktop ? (<AnimatePresence mode="wait">
        <OnboardHome key={'/onboarding/form'} />
      </AnimatePresence>
      ) : <OnboardHome />,
    },
    {
      path: HOME_DEPOT_SETUP_OR_SKIP_ROUTE,
      element: isIframe ?
        <OnboardHDHome /> : <Notfound />,
    },
    {
      path: HOME_DEPOT_SETUP_WEB,
      // eslint-disable-next-line no-nested-ternary
      element: !isIframe ? <OnboardHDHomeWeb /> : <Notfound />,
    },
    {
      path: '/onboarding/login',
      element: <OnboardingLogin />,
    },
    {
      path: '/login',
      element: <LoginLayout />,
    },
    {
      path: '/contact-support',
      element: <CustomerSupportForm />,
    },
    {
      path: '/home/preview/:id',
      children: [
        {
          index: true, // This indicates that this is the default route for the parent path
          element: <ContentPreview />,
        },
        {
          path: 'detail',
          element: <ContentArticleViewWrapper />,
        },
      ],
    },
    {
      path: '/paypal-verification',
      element: <VerificationWrapper />,
    },
    {
      // TODO to be removed. migrate to /paypal-verification
      path: '/verification',
      element: <Verification />,
    },
  ];

  if (hdOnboardingForSNFlag) {
    sharedRoutes.push({
      path: '/home/hdInstruction',
      element: <HDSetupInstructionsDetailView />,
    });
  }

  const domainRoutes: { [key: string]: RouteObject[] } = {
    [JOIST_DOMAIN]: joistRoutes,
    [SERVICE_FUSION_DOMAIN]: sfRoutes,
    [EVERPRO_DOMAIN]: [],
    [UNKNOWN_DOMAIN]: [], // no routes
  };

  // generate the selected routes
  const selectedRoutes = [
    ...sharedRoutes,
    ...(domainRoutes[appDomain]),
    {
      path: '*',
      element: <Notfound />,
    },
  ];

  const sentryUseRoutes = Sentry.wrapUseRoutes(useRoutes);

  const routesComponent = sentryUseRoutes(selectedRoutes);

  return routesComponent;
}
