import {
  CardContent,
  Container, Skeleton, Theme,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';

const containerStyle = (theme: Theme) => ({
  marginTop: theme.spacing(1),
  paddingBottom: theme.spacing(6),
} as const);

export function ContentArticleViewSkeleton() {
  const theme = useTheme();
  return (
    <Container maxWidth={'sm'} sx={[containerStyle]}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 0,
      }}>
        <Box sx={{ marginBottom: theme.spacing(2.5) }}>
          <Skeleton
            animation="wave"
            height={5}
            width="90%"
            sx={{
              marginBottom: 4,
            }}
          />
          <Skeleton
            animation="wave"
            height={5}
            width="90%"
            sx={{
              marginBottom: 25,
            }}
          />
          <Skeleton
            sx={() => ({
              [theme.breakpoints.down('sm')]: {
                height: 180,
                width: '100%',
              },
              height: 400,
            })}
            animation="wave" variant="rectangular" />
        </Box>
        <CardContent>
        </CardContent>
        <Skeleton
          animation="wave"
          height={5}
          width="90%"
          sx={{
            marginTop: 4,
          }}
        />
        <Skeleton
          animation="wave"
          height={5}
          width="70%"
          sx={{
            marginTop: 2,
          }}
        />
      </Box>
    </Container >
  );
}
