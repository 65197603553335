import { useCompanyEnrollmentInfo } from '../../common/useCompanyEnrollmentInfo';
import { ContentArticleView } from './ContentArticleView';

export default function ContentArticleViewJoist() {
  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: false,
    redirectPath: '/in-app/onboarding',
  });

  return (
    <ContentArticleView/>
  );
}
