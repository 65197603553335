/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../storeSetup';
import {
  getHomeDepotMemberRelation,
  getLennoxMemberRelation,
  getPayPalAccount, getSelectedPartnersForMember,
  MemberPartnerRelation, PayPalAccount, setLennoxMemberKey, setPayPalAccountId,
  updateMemberToPartner, getYearlyPayments,
  getMemberConfig,
  getHDSupplyMemberRelation,
  verifyAndUpdatePaypalEmail,
  CompanyPartnerRelation,
  getCompanyPartnerRelations,
} from '../thunks/dashboardThunk';
import { MemberConfig } from '../../common/models/Member';
import { HDSetupFlowSteps, HDSupplyEnrollmentStatus } from '../../common/enums';

interface DashboardState {
  payPalAccount: PayPalAccount | undefined;
  getPayPalAccountState: 'idle' | 'pending';
  setPayPalAccountIdState: 'idle' | 'pending';
  lennoxMemberRelation: MemberPartnerRelation | undefined;
  homeDepotMemberRelation: MemberPartnerRelation | undefined;
  selectedPartners: MemberPartnerRelation[];
  getLennoxMemberRelationState: 'idle' | 'pending';
  getHomeDepotMemberRelationState: 'idle' | 'pending';
  setLennoxMemberKeyState: 'idle' | 'pending';
  getSelectedPartnersForMemberState: 'idle' | 'pending';
  getYearlyPaymentsState: 'idle' | 'pending';
  myAccountRenderIndex: {
    value: number;
    descr: string;
  }
  updateMemberToPartnerState: 'idle' | 'pending';
  HDSetupFlowStepsCompletionState: HDSetupFlowSteps;
  yearlyPayments: { year: number, total: string }[];
  getMemberConfigState: 'idle' | 'pending';
  memberConfig: MemberConfig | undefined;
  HDSupplyEnrollmentState: HDSupplyEnrollmentStatus;
  hdSupplyMemberRelation: MemberPartnerRelation | undefined;
  getHDSupplyMemberRelationState: 'idle' | 'pending';
  memberPaypalAuthorizationToken: string | null;
  companyPartnerRelations: CompanyPartnerRelation[] | undefined;
  backUrl: string | null;
  showDashboardToolbar: boolean;
}

// Define the initial state using that type
const initialState: DashboardState = {
  payPalAccount: undefined,
  selectedPartners: [],
  getPayPalAccountState: 'pending',
  setPayPalAccountIdState: 'idle',
  lennoxMemberRelation: undefined,
  homeDepotMemberRelation: undefined,
  getLennoxMemberRelationState: 'pending',
  getHomeDepotMemberRelationState: 'pending',
  setLennoxMemberKeyState: 'idle',
  getSelectedPartnersForMemberState: 'pending',
  getYearlyPaymentsState: 'idle',
  myAccountRenderIndex: {
    value: 0,
    descr: 'Active tab in my account page',
  },
  updateMemberToPartnerState: 'idle',
  HDSetupFlowStepsCompletionState: HDSetupFlowSteps.None,
  yearlyPayments: [],
  getMemberConfigState: 'pending',
  memberConfig: undefined,
  HDSupplyEnrollmentState: HDSupplyEnrollmentStatus.Pending,
  hdSupplyMemberRelation: undefined,
  getHDSupplyMemberRelationState: 'pending',
  memberPaypalAuthorizationToken: null,
  companyPartnerRelations: undefined,
  backUrl: null,
  showDashboardToolbar: false,
};

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setMyAccountRenderIndex: (state, action: PayloadAction<number>) => {
      state.myAccountRenderIndex.value = action.payload;
    },
    UpdateHDSetupFlowStepsCompletionState: (state, action: PayloadAction<HDSetupFlowSteps>) => {
      state.HDSetupFlowStepsCompletionState = action.payload;
    },
    resetHomeDepotMemberRelation: (state) => {
      state.homeDepotMemberRelation = undefined;
    },
    UpdateHDSupplyEnrollmentState: (state, action: PayloadAction<HDSupplyEnrollmentStatus>) => {
      state.HDSupplyEnrollmentState = action.payload;
    },
    UpdateMemberPaypalAuthorizationToken: (state, action: PayloadAction<string>) => {
      state.memberPaypalAuthorizationToken = action.payload;
    },
    UpdateBackUrl: (state, action: PayloadAction<string>) => {
      state.backUrl = action.payload;
    },
    UpdateShowDashboardToolbar: (state, action: PayloadAction<boolean>) => {
      state.showDashboardToolbar = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPayPalAccount.pending, (state, action) => {
        state.getPayPalAccountState = 'pending';
      })
      .addCase(getPayPalAccount.fulfilled, (state, action) => {
        state.getPayPalAccountState = 'idle';
        state.payPalAccount = action.payload;
      })
      .addCase(getPayPalAccount.rejected, (state, action) => {
        state.getPayPalAccountState = 'idle';
      })
      .addCase(setPayPalAccountId.pending, (state, action) => {
        state.setPayPalAccountIdState = 'pending';
      })
      .addCase(setPayPalAccountId.fulfilled, (state, action) => {
        state.setPayPalAccountIdState = 'idle';
        state.payPalAccount = action.payload;
      })
      .addCase(setPayPalAccountId.rejected, (state, action) => {
        state.setPayPalAccountIdState = 'idle';
      })
      .addCase(verifyAndUpdatePaypalEmail.pending, (state, action) => {
        state.setPayPalAccountIdState = 'pending';
      })
      .addCase(verifyAndUpdatePaypalEmail.fulfilled, (state, action) => {
        state.setPayPalAccountIdState = 'idle';
        state.payPalAccount = action.payload;
      })
      .addCase(verifyAndUpdatePaypalEmail.rejected, (state, action) => {
        state.setPayPalAccountIdState = 'idle';
      })
      .addCase(setLennoxMemberKey.pending, (state, action) => {
        state.setLennoxMemberKeyState = 'pending';
      })
      .addCase(setLennoxMemberKey.fulfilled, (state, action) => {
        state.setLennoxMemberKeyState = 'idle';
        state.lennoxMemberRelation = action.payload;
      })
      .addCase(setLennoxMemberKey.rejected, (state, action) => {
        state.setLennoxMemberKeyState = 'idle';
      })
      .addCase(getLennoxMemberRelation.pending, (state, action) => {
        state.getLennoxMemberRelationState = 'pending';
      })
      .addCase(getLennoxMemberRelation.fulfilled, (state, action) => {
        state.getLennoxMemberRelationState = 'idle';
        state.lennoxMemberRelation = action.payload;
      })
      .addCase(getLennoxMemberRelation.rejected, (state, action) => {
        state.getLennoxMemberRelationState = 'idle';
      })
      .addCase(getHomeDepotMemberRelation.pending, (state, action) => {
        state.getHomeDepotMemberRelationState = 'pending';
      })
      .addCase(getHomeDepotMemberRelation.fulfilled, (state, action) => {
        state.getHomeDepotMemberRelationState = 'idle';
        state.homeDepotMemberRelation = action.payload;
      })
      .addCase(getHomeDepotMemberRelation.rejected, (state, action) => {
        state.getHomeDepotMemberRelationState = 'idle';
      })
      .addCase(getCompanyPartnerRelations.fulfilled, (state, action) => {
        state.companyPartnerRelations = action.payload;
      })
      .addCase(getSelectedPartnersForMember.pending, (state, action) => {
        state.getSelectedPartnersForMemberState = 'pending';
      })
      .addCase(getSelectedPartnersForMember.fulfilled, (state, action) => {
        state.getSelectedPartnersForMemberState = 'idle';
        state.selectedPartners = action.payload;
      })
      .addCase(getSelectedPartnersForMember.rejected, (state, action) => {
        state.getSelectedPartnersForMemberState = 'idle';
      })
      .addCase(updateMemberToPartner.pending, (state, action) => {
        state.updateMemberToPartnerState = 'pending';
      })
      .addCase(updateMemberToPartner.fulfilled, (state, action) => {
        state.updateMemberToPartnerState = 'idle';
        state.homeDepotMemberRelation = action.payload;
      })
      .addCase(updateMemberToPartner.rejected, (state, action) => {
        state.updateMemberToPartnerState = 'idle';
      })
      .addCase(getYearlyPayments.pending, (state, action) => {
        state.getYearlyPaymentsState = 'pending';
      })
      .addCase(getYearlyPayments.fulfilled, (state, action) => {
        state.getYearlyPaymentsState = 'idle';
        state.yearlyPayments = action.payload;
      })
      .addCase(getYearlyPayments.rejected, (state, action) => {
        state.getYearlyPaymentsState = 'idle';
      })
      .addCase(getMemberConfig.pending, (state, action) => {
        state.getMemberConfigState = 'pending';
      })
      .addCase(getMemberConfig.fulfilled, (state, action) => {
        state.memberConfig = action.payload;
        state.getMemberConfigState = 'idle';
      })
      .addCase(getMemberConfig.rejected, (state, action) => {
        state.getMemberConfigState = 'idle';
      })
      .addCase(getHDSupplyMemberRelation.pending, (state, action) => {
        state.getHDSupplyMemberRelationState = 'pending';
      })
      .addCase(getHDSupplyMemberRelation.fulfilled, (state, action) => {
        state.getHDSupplyMemberRelationState = 'idle';
        state.hdSupplyMemberRelation = action.payload;
      })
      .addCase(getHDSupplyMemberRelation.rejected, (state, action) => {
        state.getHDSupplyMemberRelationState = 'idle';
      });
  },
});

export const {
  setMyAccountRenderIndex,
  UpdateHDSetupFlowStepsCompletionState,
  resetHomeDepotMemberRelation,
  UpdateHDSupplyEnrollmentState,
  UpdateMemberPaypalAuthorizationToken,
  UpdateBackUrl,
  UpdateShowDashboardToolbar,
} = DashboardSlice.actions;

export const selectPayPalAccount =
  (state: RootState) => state.dashboard.payPalAccount;
export const selectGetPayPalAccountState =
  (state: RootState) => state.dashboard.getPayPalAccountState;
export const selectSetPayPalAccountIdState =
  (state: RootState) => state.dashboard.setPayPalAccountIdState;

export const selectLennoxMemberRelation =
  (state: RootState) => state.dashboard.lennoxMemberRelation;
export const selectHomeDepotMemberRelation =
  (state: RootState) => state.dashboard.homeDepotMemberRelation;
export const selectGetLennoxMemberRelationState =
  (state: RootState) => state.dashboard.getLennoxMemberRelationState;
export const selectGetHomeDepotMemberRelationState =
  (state: RootState) => state.dashboard.getHomeDepotMemberRelationState;
export const selectSetLennoxMemberKeyState =
  (state: RootState) => state.dashboard.setLennoxMemberKeyState;
export const selectGetSelectedPartnersForMemberState =
  (state: RootState) => state.dashboard.getSelectedPartnersForMemberState;
export const selectGetYearlyPaymentsState =
  (state: RootState) => state.dashboard.getYearlyPaymentsState;
export const selectSelectedPartners =
  (state: RootState) => state.dashboard.selectedPartners;
export const selectMyAccountRenderIndex =
  (state: RootState) => state.dashboard.myAccountRenderIndex;
export const selectUpdateMemberToPartnerState =
  (state: RootState) => state.dashboard.updateMemberToPartnerState;
export const selectHDSetupFlowStepsCompletionState =
  (state: RootState) => state.dashboard.HDSetupFlowStepsCompletionState;
export const selectGetMemberConfigState =
  (state: RootState) => state.dashboard.getMemberConfigState;
export const selectMemberConfig =
  (state: RootState) => state.dashboard.memberConfig;
export const selectHDSupplyEnrollmentState =
  (state: RootState) => state.dashboard.HDSupplyEnrollmentState;
export const selectHDSupplyMemberRelation =
  (state: RootState) => state.dashboard.hdSupplyMemberRelation;
export const selectGetHDSupplyMemberRelationState =
  (state: RootState) => state.dashboard.getHDSupplyMemberRelationState;
export const selectMemberPaypalAuthorizationToken =
  (state: RootState) => state.dashboard.memberPaypalAuthorizationToken;
export const selectGetCompanyPartnerRelations =
  (state: RootState) => state.dashboard.companyPartnerRelations;
export const selectBackUrl =
  (state: RootState) => state.dashboard.backUrl;
export const selectShowDashboardToolbar =
  (state: RootState) => state.dashboard.showDashboardToolbar;

export default DashboardSlice.reducer;
