import sanitizeHtml, { Attributes } from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li'],
  allowedAttributes: {
    a: ['href'],
    img: ['src', 'sizes', 'width'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
  transformTags: {
    img: (tagName: string, attribs: Attributes) => ({
      tagName: 'img', // Keep the same tag
      attribs: {
        ...attribs, // Spread existing attributes
        width: '100%', // Set a default width
      },
    }),
  },
};

const sanitize = (dirty: string, options: sanitizeHtml.IOptions) => ({
  __html: sanitizeHtml(
    dirty,
    { ...defaultOptions, ...options },
  ),
});

interface SanitizeHTMLProps {
  html: string
  options?: sanitizeHtml.IOptions
}

export const SanitizeHTML = (options: SanitizeHTMLProps) => (
  <div style={{ width: '100%' }} dangerouslySetInnerHTML={sanitize(options.html, options.options || {})} />
);
