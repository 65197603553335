export const TestIds = {
  login: {
    emailTxt: 'login-email',
    loginCodeTxt: 'login-login-code',
    loginBtn: 'login-login-button',
    editEmailAddressLnk: 'login-edit-email-address',
    contactSupportLnk: 'login-contact-support',
  },
  onboarding: {
    enrollBtn: 'onboarding-enroll',
    roleAtCompanyLst: 'onboarding-role-at-company',
    nextBtn: 'onboarding-next',
    previousLnk: 'onboarding-previous-link',
    homedepotShopTitle: 'onboarding-hd-shop-title',
    homedepotShopYesBtn: 'onboarding-hd-shop-option-yes-btn',
    homedepotShopNoBtn: 'onboarding-hd-shop-option-no-btn',
    homedepotShopNextBtn: 'onboarding-hd-shop-next-btn',
    homedepotXtraYesBtn: 'onboarding-hd-xtra-option-yes-btn',
    homedepotXtraNoBtn: 'onboarding-hd-xtra-option-no-btn',
    homedepotXtraUnsureBtn: 'onboarding-hd-xtra-option-unsure-btn',
    homedepotXtraLaterLnk: 'onboarding-hd-xtra-later-lnk',
    homedepotLaunchBtn: 'onboarding-hd-launch-btn',
    homedepotDoneBtn: 'onboarding-hd-done-btn',
    homedepotShowAgainLnk: 'onboarding-hd-no-show-again-lnk',
    homedepotEmailAdressTxt: 'onboarding-hd-email-address-txt',
    homedepotCompleteEnrollmentBtn: 'onboarding-hd-complete-enrollment-btn',
  },
  welcomeModal: {
    welcomePageBtn: 'welcome-modal-welcome-page-btn',
    partnerSelectPageBtn: 'welcome-modal-partner-select-page-btn',
    homeDepotPartnerSelector: 'welcome-modal-home-depot-partner-selector',
    hdSupplyPartnerSelector: 'welcome-modal-hd-supply-partner-selector',
    interestPageBtn: 'welcome-modal-interest-page-btn',
  },
  followTopics: {
    hiringBtn: 'follow-topics-hiring-btn',
    pricingBtn: 'follow-topics-pricing-btn',
    leadGenerationBtn: 'follow-topics-lead-generation-btn',
    technologyBtn: 'follow-topics-technology-btn',
    marketingBtn: 'follow-topics-marketing-btn',
  },
  contentArticleView: {
    backBtn: 'content-article-view-back-btn',
    saveBtn: 'content-article-view-save-btn',
    contentOwnerName: 'content-article-view-owner-name',
    contentTitle: 'content-article-view-title',
    contentPublishedDate: 'content-article-view-published-date',
    contentTopic: 'content-article-view-topic',
  },
};
