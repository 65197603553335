import { useCompanyEnrollmentInfo } from '../../common/useCompanyEnrollmentInfo';
import AuthenticatedRoute from '../AuthenticatedRoute';
import { ContentArticleView } from './ContentArticleView';

export default function ContentArticleViewWrapper() {
  // check company enrollment status
  useCompanyEnrollmentInfo({ calledFromOnboardingComponent: false });

  return (
    <AuthenticatedRoute>
      <ContentArticleView />
    </AuthenticatedRoute>
  );
}
